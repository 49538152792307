import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
import Seo from "@components/utility/SEO";
import { KfHeroUtility } from "@klickinc/kf-react-components";

const HereNowLosapios = () => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass("page--transcript page--transcript-here-now-losapios");
	}, []);

	return (
		<>
			<section className="transcript-section">
				<div className="container container--inner">
					<KfHeroUtility addedClass="heading-hero-blue heading">
						Transcript
					</KfHeroUtility>
					<p className="mb-4"><span className="uppercase">This video is based on the personal experience of families with boys taking emflaza<sup>®</sup></span> (deflazacort). <span className="uppercase">Please speak to a healthcare provider before making treatment decisions.</span></p>
					<h2 className='orange-heading'>Here & Now With the Losapios: A DMD Story</h2>
					<p className="text-base text-emflaza-orange-100">DMD=Duchenne Muscular Dystrophy</p>
					<div className="script">
						<div className="script--intro">
							<p><span className="name">Mike:</span> <span className="copy">I'm Mike LoSapio and I have two awesome little guys that have Duchenne muscular dystrophy. Mikey, he's nine and Reid, he's seven. Mikey and Reid are absolutely two peas in a pod but just two different peas within that pod. One of the mantras that I have kind of developed for life is to have a grateful attitude.</span></p>
						</div>
						<div className="script--body">
							<h3 className='highlighted-copy'>WHAT TREATMENT OPTIONS DID YOU CONSIDER WITH YOUR DOCTORS?</h3>
							<p><span className="name">Mike:</span> <span className="copy">While discussing different options with our neurologist, EMFLAZA came up. That was, at the time, the only approved drug for Duchenne and that was something that I really wanted to get Michael on immediately. With Reid, we looked into starting treatment for EMFLAZA shortly after. I saw really great results with Michael on EMFLAZA and I really wanted to kind of keep Reid on that same course.</span></p>
							<p className="italic">Individual results vary. This is the experience of one patient.</p>
							<h3 className='highlighted-copy'>WHAT WAS THE PROCESS AS YOU WERE GETTING STARTED?</h3>
							<p><span className="name">Mike:</span> <span className="copy">I contacted the insurance company after our first denial and said I don't want my son taking an off-label drug. I want the one that is on-label for Duchenne, followed up by our doctor writing a letter and it only took one denial and it got approved after that.</span></p>
							<h3 className='highlighted-copy'>WHERE DO YOU SEEK SUPPORT WHILE MAKING TREATMENT DECISIONS?</h3>
							<p><span className="name">Mike:</span> <span className="copy">Seeking support for treatment decisions, talking to our neurologist is a big thing. They're our regular doctor, but also the Duchenne community is really strong. PTC has been an amazing asset as well, with their PTC <span className="italic">Cares</span>™ and helping out as much as possible.</span></p>
							<h3 className='highlighted-copy'>MAKING THE MOST OF THE HERE & NOW</h3>
							<p><span className="name">Mike:</span> <span className="copy">Having that spirit of gratitude has really helped me to kind of harness in just those little things. A funny joke by Reid, a little smile by Michael, and it just makes the world to me.</span></p>
							<div className="closing">
								<p className="highlighted-copy">ACTING LIKE A FAMILY BECAUSE THEY CAN</p>
								<br />
								<p className="highlighted-copy">ASK YOUR DOCTOR IF EMFLAZA CAN HELP</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HereNowLosapios;

export function Head() {
	return (
		<Seo title="Video Transcript: Here & Now With the Losapios - EMFLAZA® (deflazacort)" addSocialMeta={false}>
			<meta name="robots" content="noindex" />
		</Seo>
	);
};
